import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { colorPalette } from "./colors";

const useStyles = makeStyles(theme => ({
  content: {
    gridColumn: "1/-1",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minHeight: "16vw",
    paddingBottom: "calc(2vh + 2vw)",
    marginTop: "calc(2vh + 2vw)",
    marginBottom: "calc(2vh + 2vw)",
    paddingLeft: "calc(2vh + 2vw)",
    "& h2": {
      zIndex: 2,
      fontSize: "calc(2vh + 2vw)",
      fontWeight: 800,
      lineHeight: "4.5vw",
      marginBlockStart: 0,
      marginBlockEnd: 0,
      "& span": {
        width: "90%",
        float: "right",
        borderTop: `1.2vw solid ${colorPalette.blue}`
      }
    },

    "& p": {}
  },

  title: {
    fontWeight: 200,
    fontSize: "2.5rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem"
    },
    zIndex: 2
  },
  subtitle: {
    zIndex: 2
  },
  box: {
    background: "#transparent",
    position: "absolute",
    left: "calc(5vw + 5vh)",
    zIndex: -4,
    content: "\"\"",
    [theme.breakpoints.down("xs")]: {
      display: "none",
      top: 0
    },
    width: `24vw`,
    height: `20vw`,
    "&:before": {
      position: "absolute",
      content: "\"\"",
      zIndex: "-1",
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      borderTopColor: "#f2f3f4",
      borderRightColor: "#f2f3f4",
      border: `1vw solid transparent`,
      transition: "width 0.1s ease-out, height 0.1s ease-out 0.1s"
    },
    "&:after": {
      position: "absolute",
      content: "\"\"",
      zIndex: "-1",
      width: "100%",
      height: "100%",
      bottom: "0",
      right: "0",
      borderBottomColor: "#f2f3f4",
      borderLeftColor: "#f2f3f4",
      border: `1vw solid transparent`,
      transition:
        "border-color 0s ease-out 0.2s, width 0.1s ease-out 0.2s, height 0.1s ease-out 0.3s"
    }
  }
}));

const BoxTitle = ({ title, content }) => {
  const classes = useStyles();
  return (
    <div className={classes.content}>
      <div className={classes.box}/>
      <Typography variant="h4" className={classes.title}>{title}</Typography>
      <Typography variant="subtitle1" className={classes.subtitle}>{content}</Typography>
    </div>
  );
};

export default BoxTitle;
