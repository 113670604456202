import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { colorPalette } from "../components/colors";
import BoxTitle from "../components/BoxTitle";
import JobCard from "../components/JobCard";
import SectionTitle from "../components/SectionTitle";
import { Typography } from "@material-ui/core";
import departmentImg from "../images/department.png";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

export default ({ pageContext }) => {
  const { department } = pageContext;
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "services" } }) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(width: 200, placeholder: BLURRED)
            }
          }
        }
      }
    }
  `);
  const image = data.allFile.edges.find((edge) => edge.node.name === department.url);
  return (
    <Layout>
      <SEO title={department.name} />
      <SectionTitle
        title={department.name}
        height="100vh"
        setRef={() => {}}
        leftComponent={
          <div>
            <div
              style={{
                textAlign: "center",
                width: "100%",
                overflow: "hidden",
                height: "120px",
              }}
            >
              <GatsbyImage
                image={image.node.childImageSharp.gatsbyImageData}
                style={{ height: "170px" }}
                alt="Departman"
              />
            </div>
            <div
              style={{
                textAlign: "left",
                width: "100%",
                height: "3vw",
                fontSize: "5vw",
                fontStyle: "italic",
                color: colorPalette.blue,
                textDecoration: "italic",
              }}
            >
              "
            </div>
            <div
              style={{
                textAlign: "left",
                width: "90%",
                marginLeft: "auto",
                marginRight: "auto",
                fontSize: "2vw",
              }}
            >
              {department.desc.split("\n")[0]}
            </div>
            <div
              style={{
                textAlign: "right",
                width: "100%",
                marginTop: "-2vw",
                fontSize: "5vw",
                fontStyle: "italic",
                color: colorPalette.orange,
                textDecoration: "italic",
              }}
            >
              "&nbsp;
            </div>
          </div>
        }
        department={department}
        img={departmentImg}
      />
      <div
        style={{
          width: "80%",
          marginLeft: "20%",
        }}
      >
        <BoxTitle
          title={department.name}
          content={department.desc.split("\n").map((d) => {
            if (d.startsWith("**")) {
              return (
                <Typography variant="h6">
                  {d.substring(2)}
                  <br />
                </Typography>
              );
            } else {
              return (
                <>
                  {d}
                  <br />
                </>
              );
            }
          })}
        />
        {department.positions && (
          <div
            style={{
              display: "grid",
              gridGap: "1.5vw",
              gridTemplateColumns: "repeat(auto-fit, minmax(250px, 50%))",
              marginTop: "1.5vh",
              marginBottom: "1.5vh",
            }}
          >
            {department.positions.map((position) => (
              <JobCard
                job={{
                  ...position,
                  department: {
                    id: department.id,
                    name: department.name,
                  },
                }}
                key={position.id}
              />
            ))}
          </div>
        )}
      </div>
    </Layout>
  );
};
