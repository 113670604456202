import { colorPalette } from "./colors";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from "gatsby";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const styles = (theme) => ({
  root: {
    padding: "16px",
    background: colorPalette.grey,
    height: "200px",
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    color: colorPalette.black,

    "& h3": {
      marginBlockStart: 0,
    },

    "&:hover": {
      background: colorPalette.orange,
      color: "white",
    },
  },
});
const JobCard = ({ classes, job }) => (
  <Link to={job.micrositeUrl}>
    <div className={classes.root}>
      <h3>
        {job.name}
        <sub
          style={{
            display: "block",
            fontWeight: 500,
          }}
        >
          {job.department}
        </sub>
      </h3>
      <div
        style={{
          flex: 1,
        }}
      />
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>{job.location}</div>
        <ArrowForwardIcon
          fontSize="small"
          style={{
            alignSelf: "flex-end",
          }}
        />
      </div>
    </div>
  </Link>
);

export default withStyles(styles)(JobCard);
